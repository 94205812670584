import React, { FC } from "react";

import icon from "assets/images/icon.png";

const Profile: FC<{ className?: string }> = ({ className }) => {

    return (
        <section className={ className + " p-4 md:pr-0 border-t border-gray-50 md:border-none" }>

            <img
                src={ icon } alt="kyoya0819"
                className="block w-32 h-32 rounded-full mx-auto"
            />
            <p className="text-center mb-4">kyoya0819</p>

            <div className="text-xs mb-4">
                千葉県の日本大学の附属高校に通う高校3年生。2003年生まれの18歳。<br />
                PHP（Laravel, CakePHP）を用いたバックエンド開発が主。
                そのほかにも、React等を用いたフロントエンド実装や、サーバー構築、ロゴ・デザイン作成、SEO対策なども経験。
            </div>

            <ul className="flex flex-wrap text-sm justify-center">
                <li><Link href="https://twitter.com/kyoya0819">Twitter</Link></li>
                <li><Link href="https://github.com/kyoya0819" >GitHub</Link></li>
                <li><Link href="https://kyoya0819.com"        >HOMEPAGE</Link></li>
            </ul>
        </section>
    );
};
export default Profile;


const Link: FC<{ href: string }> = (props) => {
    const { href, children } = props;

    return (
        <a
            href={ href } target="_blank" rel="noopener noreferrer"
            className="inline-block mb-2 mx-1 p-2 rounded-md bg-gray-50 hover:bg-gray-100 active:bg-gray-200 transition-colors"
        >
            { children }
        </a>
    );
};