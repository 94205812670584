import React, { FC } from "react";

import Header from "./Header";
import Footer from "./Footer";
import Profile from "./Profile";

const Layout: FC<{location: Location}> = (props) => {
    const { location, children } = props;

    const rootPath = `${__PATH_PREFIX__}/`;
    const isRootPath = location.pathname === rootPath;

    return (
        <div data-is-root-path={isRootPath}>
            <Header />
            <main>
                <div className="inner md:flex">

                    <div className="w-full md:border-r md:border-gray-50">
                        { children }
                    </div>

                    <Profile className="md:w-96" />
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Layout;
