import React, { FC } from "react";
import { Link } from "gatsby";

const Header: FC = () => {

    return (
        <header className="w-full h-16 border-b border-gray-100">
            <div className="inner flex items-center h-full">
                <h1 className="text-2xl font-bold">
                    <Link to="/" className="text-black hover:text-gray-900 active:text-gray-800 hover:no-underline">
                        kyoya0819&rsquo;s Blog
                    </Link>
                </h1>
            </div>
        </header>
    );
};
export default Header;