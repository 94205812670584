import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

import defaultImage from "assets/images/thumbnail.png";

interface SeoInterface {
    title?: string,
    description?: string,
    lang?: string,
    meta?: HTMLMetaElement[],
    image?: string
}

const Seo: FC<SeoInterface> = (props) => {
    const { description, lang, meta, title, image } = props;

    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        siteUrl
                        social {
                            twitter
                        }
                    }
                }
            }
        `
    );

    const metaDescription = description || site.siteMetadata.description;
    const defaultTitle = site.siteMetadata.title;
    const siteUrl = site.siteMetadata.siteUrl;
    const ogpUrl = image ?? `${siteUrl}${defaultImage}`;
    const pageTitle = title ? title + " | " + defaultTitle : defaultTitle;

    return (
        <Helmet
            htmlAttributes={{ lang: lang }}
            title={ pageTitle }
            meta={
                [
                    { name: `description`,         content: metaDescription },
                    { name: `twitter:card`,        content: "summary_large_image" },
                    { name: `twitter:creator`,     content: site.siteMetadata?.social?.twitter || `` },
                    { name: `twitter:title`,       content: pageTitle },
                    { name: `twitter:description`, content: metaDescription },

                    { property: `og:title`,       content: pageTitle },
                    { property: `og:description`, content: metaDescription },
                    { property: `og:type`,        content: "website" },
                    { property: `og:image`,       content: ogpUrl }
                ].concat(meta ?? [])
            }
        />
    );
};
export default Seo;