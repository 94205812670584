import React, { FC } from "react";

const Footer: FC = () => {

    return (
        <footer className="border-t border-gray-100 py-4 text-center text-sm">
            <div className="inner">
                (C) 2021 <a href="https://kyoya0819.com" target="_blank" rel="noopener noreferrer">kyoya0819</a>
            </div>
        </footer>
    );
};
export default Footer;